<template>
  <div id="incomeSum" style="width:577px;height:224px;display:inline-block"></div>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';
export default {
  name:'incomeSum',
  props:{

  },
  data() {
    return {

    }
  },
  created(){
  },
  methods:{
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list){
      let seriesData = list.map(v=>{
        return {
          value: v.count,
          name: v.type
        }
      })
      var myChart = echarts.init(document.getElementById('incomeSum'));
      myChart.setOption({
        tooltip: {
          trigger: 'item',
        },
        legend: {
          orient: "vertical",
          x: "left",
          // bottom: 'left'
        },
        series: [ 
          {
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: '{b}:{c}' + '\n\r' + '({d}%)'+'',
              normal:{},
              
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: seriesData
          }
        ]
      })
      window.onresize = function(){
        myChart.resize()
      }
    }
  }
}
</script>