<template>
  <div style="width:100%;height:600px;padding:10px">
    <el-row :gutter="20">
      <el-col :xs="9" :sm="9" :lg="9">
        <div style="height:320px">
          <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="进项" name="first">
              <div style="text-align:center">
                <span style="margin-right:30px">张数:{{allCount}}</span>
                <span style="margin-right:30px">合计{{allAmount}}元</span>
                <span style="margin-right:30px">税额{{allTax}}元</span>
              </div>
              <incomeSum ref="incomeSum"></incomeSum>
            </el-tab-pane>
            <el-tab-pane label="销项" name="second">
              <div style="text-align:center">
                <span style="margin-right:30px">张数:{{outAllCount}}</span>
                <span style="margin-right:30px">合计{{outAllAmount}}元</span>
                <span style="margin-right:30px">税额{{outAllTax}}元</span>
              </div>
              <outPutSum ref="outPutSum"></outPutSum>
            </el-tab-pane>
            <el-tab-pane label="费用" name="third">
              <feeSum ref="feeSum"></feeSum>
            </el-tab-pane>
            <el-tab-pane label="税款统计" name="fourth">
              <taxSum ref="taxSum"></taxSum>
            </el-tab-pane>
            <el-tab-pane label="销售额统计" name="fifth">
              <div class="total_amount">
                <!-- <h5 >一般纳税人资格认定提醒</h5>
                <h6 >收入未超标，不想申请一般纳税人则下月开票收入（不含税）请控制在【<span>{{smallAmonutOut}}</span>】元内</h6>
                <h6 >收入<span style="color:red">超标</span></h6> -->
                <div class="bottom_btn">
                  <label>连续12个月收入合计：</label>
                  <p>{{totalAmountOut}}</p>
                </div>
              </div>
              <div class="bottom_month_amount">
                <div class="top_save">
                  <p>请补充各月不含税收入</p>
                  <el-button type="primary" size="small" @click="saveValue()">
                    <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
                    <span  > 保存</span>
                  </el-button>
                </div>
                <div class="month_box">
                  <div class="month_item" v-for="(itemM,indexM) in outStatisticsList" :key="indexM">
                    <h4>{{itemM.period}}</h4>
                    <input v-model.number="itemM.amount" placeholder="" />
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :xs="7" :sm="7" :lg="7">
        <el-tabs type="border-card" >
          <el-tab-pane label="银行存款">
            <bankSum ref="bankSum"></bankSum>
          </el-tab-pane> 
        </el-tabs>
      </el-col>
      <el-col :xs="8" :sm="8" :lg="8">
        <span class="rightSpan">
          <div>库存现金</div>
          <div>{{cash}}</div>
          <i class="iconfont icon-zijin iconStyle"></i>
        </span>
        <span class="rightSpan">
          <div>应收账款</div>
          <div>{{yszk}}</div>
          <i class="iconfont icon-zijin iconStyle"></i>
        </span>
        <span class="rightSpan">
          <div>应付账款</div>
          <div>{{yfzk}}</div>
          <i class="iconfont icon-zijin iconStyle"></i>
        </span>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top:30px">
      <el-col :xs="8" :sm="8" :lg="8">
        <div class="left_item">
          <h5>记账凭证</h5>
          <div class="clearfix" style="padding-left: 30px;">
            <p>总数：<span>{{voucher}}</span>张</p>
            <p>附件<span>{{billVoucherCount}}</span>张</p>
          </div>
          <div class="center_fonts clearfix">
            <div class="item_font claarfix">
              <p>清单凭证<span>{{vouBill}}</span>张</p>
              <h6>生成</h6>
            </div>
            <div class="item_font claarfix">
              <p>结转凭证<span>{{vouCarry}}</span>张</p>
              <h6>结转</h6>
            </div>
            <div class="item_font claarfix">
              <p>税金凭证<span>{{vouTax}}</span>张</p>
              <h6>计提</h6>
            </div>
            <div class="item_font claarfix">
              <p>手工凭证<span>{{vouHand}}</span>张</p>
              <h6>计提</h6>
            </div>
          </div>
          <div class="btns">
            <div @click="gotoVoucher">
              <el-icon><CirclePlus /></el-icon><span  > 新增凭证</span></div>
            <div @click="gotoSubject">
              <el-icon><Tickets /></el-icon><span  > 科目余额表</span></div>
          </div>
        </div>
      </el-col>
      <el-col :xs="16" :sm="16" :lg="16">
        <rightAllTotal ref="rightAllTotal"></rightAllTotal>
      </el-col>
    </el-row>
    
  </div>
</template>

<script>
import { defineComponent  } from 'vue'
import { indexTax ,getOutStatistics ,outStatisticsSave } from "@/api/home"
import incomeSum from './components/incomeSum.vue'
import outPutSum from './components/outPutSum.vue'
import feeSum from './components/feeSum.vue'
import taxSum from "./components/taxSum"
import bankSum from "./components/bankSum"
import rightAllTotal from "./components/rightAllTotal"
export default defineComponent({
  name: "etaxHomeIndex",
  components:{
    incomeSum,
    outPutSum,
    feeSum,
    taxSum,
    bankSum,
    rightAllTotal
  },
  created(){
    this.getList()
  },
  data(){
    return {
      activeName: 'first',
      cash:'',
      yfzk:'',
      yszk:'',
      allCount:0,
      allAmount:0,
      allTax:0,
      outAllCount: 0,
      outAllAmount: 0,
      outAllTax: 0,
      voucher:0,
      billVoucherCount:0,
      vouBill:0,
      vouCarry:0,
      vouTax:0,
      vouHand:0,
      outStatisticsList:[],
      totalAmountOut:0,
      smallAmonutOut:0,
      flag:false,
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period
      },
    }
  },
  methods:{
    getList(){
      indexTax(this.listQuery).then(res=>{
        let data = res.data.data
        this.$refs.incomeSum.init(data.inSum)
        let allCount = 0
        let allAmount = 0
        let allTax = 0
        data.inSum.map(v=>{
          allCount = allCount + v.count
          allAmount = (allAmount + v.amount)
          allTax = (allTax + v.tax)
        })
        this.allCount = allCount
        this.allAmount = allAmount.toFixed(2)
        this.allTax = allTax.toFixed(2)

        this.$refs.outPutSum.init(data.outSum)
        let allCount2 = 0
        let allAmount2 = 0
        let allTax2 = 0
        data.outSum.map(v=>{
          allCount2 = allCount2 + v.count
          allAmount2 = (allAmount2 + v.amount)
          allTax2 = (allTax2 + v.tax)
        })
        this.outAllCount = allCount2
        this.outAllAmount = allAmount2.toFixed(2)
        this.outAllTax = allTax2.toFixed(2)

        this.$refs.feeSum.init(data.feeSum)
        this.$refs.taxSum.init(data.taxSum)
        this.$refs.bankSum.init(data.bankSum)
        this.$refs.rightAllTotal.init(data)
        this.cash = data.cash.amount
        this.yfzk = data.yfzk.amount
        this.yszk = data.yszk.amount
        this.voucher = data.voucher.count
        this.billVoucherCount = data.voucher.billCount
        this.vouBill = data.vouBill.count
        this.vouCarry = data.vouCarry.count
        this.vouTax = data.vouTax.count
        this.vouHand = data.vouHand.count
      })
    },
    getOutList(){
      getOutStatistics({}).then(res=>{
        this.outStatisticsList = res.data.data.list
        let totalAmountOut = 0
        this.outStatisticsList.map(v=>{
          totalAmountOut += v.amount
        })
        this.totalAmountOut = totalAmountOut.toFixed(2)
        this.smallAmonutOut = (5000000 - this.totalAmountOut).toFixed(2)
      })
    },
    handleClick(tab, event) {
      // this.getList()
      if( tab.props.name == "fifth"){
        this.getOutList()
      }
    },
    saveValue(){
      outStatisticsSave(this.outStatisticsList).then(res=>{
        if(res.data.msg == "success"){
          this.getOutList()
          this.$qzfMessage("保存成功")
        }
      })
    },
    gotoSubject(){
      this.$router.push({
        path: "/bookkeeps/accountBook",
        name: "accountBook"
      });
    },
    gotoVoucher(){
      this.$store.dispatch('commons/setParam', {addVoucherId: 0})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    }
  }
})
</script>

<style lang="scss" scoped>
  .card-header {
    height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box-card {
    width: 30%;
  }
  .title{
    font-size: 16px;
    font-weight: 600;
  }
  .spbh{
    color: $color-main;
    position: relative;
    float: right;
    top: -60px;
    span{
      margin-right: 10px; 
      margin-left: 10px;
      img{
        position: relative;
        top:7px
      }
    }
  }
  .noDate{
    text-align: center;
    font-size: 31px;
    font-weight: 500;
    color: #1482f0;
    margin-top: -104px;
  }
  .rightSpan{
    width: 30%;
    margin-right:10px;
    height: 100%;
    background-color: #eee;
    display: inline-block;
    text-align:center;
    line-height: 50px;
    position:relative;
  }
  .left_item{
  width: 100%;
  margin: 0 auto;
  background: #eee;
  // box-shadow:2px 2px 10px rgba(110, 110, 110, 0.2);
  padding: 15px;
  // margin-left: 20px;
  
  height: 360px;
  h5{
    margin-top: 20px;
    font-size: 16px;
    color: #000;
    line-height: 32px;
    font-weight: normal;
    padding-left: 30px;
  }
  p{
    float: left;
    margin-right: 15px;
    width: 46%;
    font-size: 14px;
    color: #333;
    line-height: 32px;
  }
  p:last-child{
    margin-right: 0;
  }
}
.center_fonts{
  width: 100%;
  margin:0 auto;
  margin-top: 10px;
  padding-left: 30px;
  .item_font{
    width: 100%;
    margin-top: 8px;
    p{
      float: left;
      width: 60%;
      font-size: 13px;
      color: #333;
      line-height: 24px;
      span{
        padding: 0 5px 0 10px;
        color: $color-main;
      }
    }
    h6{
      float: left;
      width: 30%;
      font-size: 13px;
      color: $color-main;
      line-height: 24px;
      cursor: pointer;
    }
    
  }
}
.btns{
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  div{
    width:60%;
    margin: 0 auto;
    line-height: 32px;
    font-size: 14px;
    color: #fff;
    background: $color-main;
    margin-top: 20px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
  }
}
.total_amount{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 15px;
    color: #000;
    line-height: 26px;
    font-weight: normal;
  }
  h6{
    font-size: 13px;
    margin-top: 5px;
    color: #333;
    line-height: 26px;
    font-weight: normal;
  }
  .bottom_btn{
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction:row;
    label{
      line-height: 26px;
      color: #333;
      font-size: 13px;
      font-weight: normal;
    }
    p{
      min-width: 200px;
      line-height: 26px;
      color: #F15A23;
      font-size: 13px;
      font-weight: normal;
    }
  }
}
.bottom_month_amount{
  height: 202px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  border-top: 1px solid #c2c2c2;
  .top_save{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    p{
      line-height: 28px;
      color: #333;
      font-size: 13px;
      font-weight: normal;
    }
  }
}
.month_box{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  margin-top: 10px;
  border-right: 1px solid #c2c2c2;
  border-top: 1px solid #c2c2c2;
  .month_item{
    width:16.66666%;
    h4{
      width: 99%;
      line-height: 30px;
      height: 30px;
      background: #eee;
      color: #333;
      font-size: 13px;
      text-align: center;
      border-left: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
    }
    input{
      width: 100%;
      line-height:30px;
      height: 30px;
      padding: 0 0px;
      color: #333;
      font-size: 13px;
      text-align: center;
      border-bottom: 1px solid #c2c2c2;
      border-left: 1px solid #c2c2c2;
    }
  }
}
.iconStyle{
  font-size: 50px;
  color: #fff;
  position: absolute;
  bottom: 20px;
}
</style>